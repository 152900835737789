import { Platform } from 'react-native';

import { isMobile, region } from 'utils/environment';
import { Region } from 'utils/environment/types';

export const isCashAppSupported = () => {
  if (region !== Region.US) {
    return false;
  }

  // Cashapp not currently supported on mobile web environments
  if (isMobile()) {
    return false;
  }

  // Cashapp not currently supported on android
  if (Platform.OS === 'android') {
    return false;
  }

  // Cashapp not currently supported on iOS
  if (Platform.OS === 'ios') {
    return false;
  }

  return true;
};

import React from 'react';

import { ImageURISource } from 'react-native';

export enum MarkerTypes {
  User = 'user',
  UserManual = 'userManual',
  Driver = 'driver',
  Destination = 'destination',
  StoreOpen = 'storeActive',
  StoreNotAvailable = 'storeDisabled',
  StoreFavOpen = 'storeFavOpen',
  StoreFavNotAvailable = 'storeFavClosed',
  StoreFocused = 'storeInActive',
  StoreFocusedNotAvailable = 'storeSelectedInactive',
  StoreOpenSelected = 'storeOpenSelected',
  StoreFocusedSelected = 'storeFocusedSelected',
  StoreFavSelected = 'storeFavSelected',
}

export interface IMarkerInput {
  id: string;
  type: MarkerTypes;
  location: CenterPoint;
  onPress?: () => void;
  title?: string;
  isSelected?: boolean;
  isFocused?: boolean;
  enableMapListExperiment?: boolean;
}

export interface IMarker extends IMarkerInput {
  icon: React.ReactNode | ImageURISource; // changed to maintain compatibility between WEB and APP
  preventMarkerPress: boolean;
}

export type MarkersMap = {
  [key: string]: IMarker;
};

type MapEventListener = (...args: any[]) => void;

export interface IUseMapArgs {
  initialZoomAndroid?: number;
  initialZoomIOS?: number;
  eventListeners?: {
    [key: string]: MapEventListener | Array<MapEventListener>;
  };
  position?: CenterPoint;
  disableControls?: boolean;
  isStaticMap?: boolean;
}

export type PanToFn = (
  newPosition: {
    lat?: number | null;
    lng?: number | null;
  } | null
) => Promise<void>;

export type FitAndCenterFromCoordsFn = (coordsArray: CenterPoint[]) => void;

export type CenterPoint = {
  lat: number;
  lng: number;
};

export interface ILocation extends CenterPoint {
  accuracy?: number;
}

export type IUseMapHook = (
  args?: IUseMapArgs
) => {
  center: CenterPoint;
  createMarker: (args: IMarkerInput) => void | string;
  createMarkerList?: (args: IMarkerInput[]) => void;
  fitAndCenterFromCoords: FitAndCenterFromCoordsFn;
  map: React.ReactNode;
  panTo: PanToFn;
  updateMarkerPosition?: (markerKey: string, position: CenterPoint) => void;
  zoom: number;
};

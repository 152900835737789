import { useCallback } from 'react';

import { Header, Pressable } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { SetState } from '@rbi-ctg/frontend';
import { AnimatedFlatList } from 'components/animated-flat-list';
import Modal from 'components/modal';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { LoyaltyOfferWithInlineAlertMessage } from 'state/loyalty/hooks/types';
import { useLoyaltyOfferGroupsById } from 'state/loyalty/hooks/use-loyalty-offer-groups-by-id';
import { routes } from 'utils/routing';

import { LoadingAnimation } from '../loyalty-claim-points/loyalty-claim-points.styled';
import { StyledBackIcon } from '../loyalty-incentives-components/incentive-details/incentive-details.styled';
import { LoyaltyLoaderContainer } from '../loyalty-loader/loyalty-loader.styled';

import { renderOfferItem } from './loyalty-list-item';
import { LoyaltyIncentivesContainer, OffersItemHeader } from './loyalty-offers.styled';

type OffersGroupsDetailsProps = {
  setAddOffersCallbackUrl: SetState<string>;
};

export const OffersGroupsDetails: React.FC<OffersGroupsDetailsProps> = ({
  setAddOffersCallbackUrl,
}) => {
  const {
    params: { offerGroupId },
  } = useRoute<{ offerGroupId?: string }>();

  const { offers, groupName, loading: offersLoading } = useLoyaltyOfferGroupsById(offerGroupId);
  const { linkTo } = useNavigation();
  const { formatMessage } = useIntl();

  const handleOfferPress = useCallback(
    (incentive: LoyaltyOfferWithInlineAlertMessage) => {
      const incentiveId = incentive.loyaltyEngineId;
      const qs = offerGroupId ? `?groupId=${offerGroupId}` : '';
      linkTo(`${routes.loyaltyOfferList}/${incentiveId}${qs}`);
    },
    [linkTo, offerGroupId]
  );

  const renderItem = ({ item }: { item: LoyaltyOfferWithInlineAlertMessage }) => {
    return renderOfferItem({
      item,
      handlePress: () => {
        if (item?.inlineAlertMessage) {
          return;
        }
        setAddOffersCallbackUrl(`${routes.loyaltyOfferGroup}/${offerGroupId}`);
        handleOfferPress(item);
      },
      skipAnimation: true,
    });
  };

  const handleDismiss = useCallback(() => {
    linkTo(routes.loyaltyOfferList);
  }, [linkTo]);

  if (!offers.length || offersLoading) {
    return (
      <LoyaltyLoaderContainer>
        <LoadingAnimation />
      </LoyaltyLoaderContainer>
    );
  }
  return (
    <>
      <Modal
        onDismiss={handleDismiss}
        testID="offers-group-items-modal"
        header={<OffersItemHeader>{groupName}</OffersItemHeader>}
        closeButton={() => (
          <Pressable onPress={handleDismiss}>
            <StyledBackIcon variant="back" />
          </Pressable>
        )}
        body={
          <LoyaltyIncentivesContainer>
            <Header alignSelf="center" marginBottom="$8">
              {formatMessage({ id: 'selectOffer' })}
            </Header>
            {!offersLoading && (
              <AnimatedFlatList
                data={offers}
                keyExtractor={(item, index) => item._id || `${index}`}
                renderItem={renderItem}
              />
            )}
          </LoyaltyIncentivesContainer>
        }
        mParticleEventData={{
          modalAppearanceEventMessage: 'Offers Group Details',
        }}
      />
    </>
  );
};

export const userMarkerSvg = `
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24Z"
      fill="#FFFFFF"
    />
  </g>
  <g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 21C17.866 21 21 17.866 21 14C21 10.134 17.866 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21Z"
      fill="#3780D6"
    />
  </g>
</svg>
`;

export const userManualMarkerSvg = `
<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.31 3A11.8 11.8 0 0 0 8.5 14.81c0 8.856 11.81 21.93 11.81 21.93s11.808-13.074 11.808-21.93A11.8 11.8 0 0 0 20.31 3m0 16.027a4.22 4.22 0 0 1-4.218-4.218 4.22 4.22 0 0 1 4.217-4.217 4.22 4.22 0 0 1 4.218 4.217 4.22 4.22 0 0 1-4.218 4.218" fill="#502314"/></svg>
`;

/// <reference types="@types/google.maps" />
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@rbilabs/universal-components';
import { debounce } from 'lodash-es';
import ReactDOM from 'react-dom';
import { getRandomId } from 'utils';

import useGoogleGeolocationLibrary from 'hooks/geolocation/use-google-geolocation-library';
import {
  storeSelectedInactive,
  storeSelectedInactiveRevamp,
} from 'hooks/use-map/icons/store-selected-inactive';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { useLocale } from 'state/intl';
import { isValidPosition } from 'utils/geolocation';
import DEFAULT_MAP_ZOOM from 'utils/restaurant/default-map-zoom';

import mapDefaults from './defaults';
import { svgToUri } from './icons';
import { destinationMarkerSvg } from './icons/destination-marker.web';
import { driverMarkerSvg } from './icons/driver-marker.web';
import {
  storeActiveMarkerSvg,
  storeActiveMarkerSvgRevamp,
  storeFocusedSelectedSvgRevamp,
} from './icons/store-marker-active.web';
import {
  storeDisabledMarkerSvg,
  storeDisabledMarkerSvgRevamp,
} from './icons/store-marker-disabled.web';
import {
  storeFavoriteClosedMarkerSvg,
  storeFavoriteClosedMarkerSvgRevamp,
} from './icons/store-marker-fav-closed.web';
import {
  storeFavoriteMarkerSvg,
  storeFavoriteMarkerSvgRevamp,
  storeFavoriteSelectedSvgRevamp,
} from './icons/store-marker-fav.web';
import {
  storeMarkerSelectedSvgRevamp,
  storeMarkerSvg,
  storeMarkerSvgRevamp,
} from './icons/store-marker.web';
import { userManualMarkerSvg, userMarkerSvg } from './icons/user-marker.web';
import { checkSingleUseMarker, getKeyForMarkerInput } from './marker-utils';
import {
  CenterPoint,
  FitAndCenterFromCoordsFn,
  IMarkerInput,
  IUseMapHook,
  MarkerTypes,
  PanToFn,
} from './types';

const MapContainer = Box.withConfig({
  backgroundColor: Styles.color.white,
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  height: '100%',
  width: 'full',
});

const markersUris = {
  [MarkerTypes.User]: svgToUri(userMarkerSvg),
  [MarkerTypes.UserManual]: svgToUri(userMarkerSvg),
  [MarkerTypes.Destination]: svgToUri(destinationMarkerSvg),
  [MarkerTypes.Driver]: svgToUri(driverMarkerSvg),
  [MarkerTypes.StoreOpen]: svgToUri(storeMarkerSvg),
  [MarkerTypes.StoreNotAvailable]: svgToUri(storeDisabledMarkerSvg),
  [MarkerTypes.StoreFavOpen]: svgToUri(storeFavoriteMarkerSvg),
  [MarkerTypes.StoreFavNotAvailable]: svgToUri(storeFavoriteClosedMarkerSvg),
  [MarkerTypes.StoreFocusedNotAvailable]: svgToUri(storeSelectedInactive),
  [MarkerTypes.StoreFocused]: svgToUri(storeActiveMarkerSvg),
};

const markersUrisRevamp = {
  [MarkerTypes.User]: svgToUri(userMarkerSvg),
  [MarkerTypes.UserManual]: svgToUri(userManualMarkerSvg),
  [MarkerTypes.StoreOpen]: svgToUri(storeMarkerSvgRevamp),
  [MarkerTypes.StoreOpenSelected]: svgToUri(storeMarkerSelectedSvgRevamp),
  [MarkerTypes.StoreFocused]: svgToUri(storeActiveMarkerSvgRevamp),
  [MarkerTypes.StoreFocusedSelected]: svgToUri(storeFocusedSelectedSvgRevamp),
  [MarkerTypes.StoreNotAvailable]: svgToUri(storeDisabledMarkerSvgRevamp),
  [MarkerTypes.StoreFavOpen]: svgToUri(storeFavoriteMarkerSvgRevamp),
  [MarkerTypes.StoreFavSelected]: svgToUri(storeFavoriteSelectedSvgRevamp),
  [MarkerTypes.StoreFavNotAvailable]: svgToUri(storeFavoriteClosedMarkerSvgRevamp),
  [MarkerTypes.Destination]: svgToUri(destinationMarkerSvg),
  [MarkerTypes.Driver]: svgToUri(driverMarkerSvg),
  [MarkerTypes.StoreFocusedNotAvailable]: svgToUri(storeSelectedInactiveRevamp),
};

const useMap: IUseMapHook = ({
  eventListeners = {},
  position,
  disableControls = false,
  isStaticMap,
} = {}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const markers = useRef<Record<string, IMarkerInput & { marker: google.maps.Marker }>>({});
  const selectedMarkerKey = useRef<string | undefined>();
  // track when the map container has been loaded into the dom
  const [isMapMounted, setIsMapMounted] = useState(false);
  const domRef = useRef<HTMLDivElement | null>(null);
  const { libraryLoaded } = useGoogleGeolocationLibrary();
  const enableMapListExperiment = useEnableMapListExperiment();

  const { region } = useLocale();
  const defaultPosition = mapDefaults[region] ?? mapDefaults.default;
  const startPosition = position
    ? {
        lat: position.lat,
        lng: position.lng,
      }
    : defaultPosition;
  const [center, setCenter] = useState<CenterPoint>(startPosition);
  const [zoom, setZoom] = useState(
    startPosition !== defaultPosition ? defaultPosition.zoom : DEFAULT_MAP_ZOOM
  );

  const mapListeners = useRef<Record<string, google.maps.MapsEventListener[]>>({});

  const addListener = (eventName: string, map: google.maps.Map, cb: Function, id: string) => {
    if (!mapListeners.current[id]) {
      mapListeners.current[id] = [];
    }

    const dereg = map.addListener(eventName, cb);
    mapListeners.current[id] = mapListeners.current[id].concat(dereg);
  };

  const clearListenerCache = (id: string) => {
    const handlers = mapListeners.current[id] || [];
    handlers.forEach(handler => {
      if (typeof handler.remove === 'function') {
        handler.remove();
      }
    });
    mapListeners.current[id] = [];
  };

  /*
   * SETUP MAP
   *
   * This loads the map api internally, sets up some options
   * and begins the whole process.
   *
   * NOTES:
   * - This needs to be an effect
   * - It has required listener clean ups
   * - Can not re-render map created from this hook, will error.
   *
   */

  const prepareMap = useCallback(
    (mapId: string): void => {
      if (!window.google.maps) {
        return;
      }
      const options = {
        center: startPosition,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ],
        disableDefaultUI: true,
        zoom,
        gestureHandling: disableControls ? 'none' : 'greedy',
      } as google.maps.MapOptions;

      const mapInstance = new window.google.maps.Map(domRef?.current!, options);

      const setClampedMapBounds = () => {
        const mapCenter = mapInstance.getCenter() as google.maps.LatLng;

        ReactDOM.unstable_batchedUpdates(() => {
          setCenter({
            lat: mapCenter.lat(),
            lng: mapCenter.lng(),
          });
        });
      };

      window.google.maps.event.addListenerOnce(mapInstance, 'idle', () => {
        const mapZoom = mapInstance.getZoom();
        setZoom(mapZoom!);
        setClampedMapBounds();
      });

      /**
       *  Called by dragstart and zoom_changed listeners to prevent keyboard remaining open on android devices
       *  when dragging/zooming map while search field has focus
       */
      const blurActiveElement = () => {
        const activeElement = document.activeElement as HTMLElement | null;
        activeElement?.blur?.();
      };

      addListener('dragstart', mapInstance, blurActiveElement, mapId);

      addListener('bounds_changed', mapInstance, debounce(setClampedMapBounds, 500), mapId);

      addListener(
        'zoom_changed',
        mapInstance,
        debounce(() => {
          blurActiveElement();
          const mapZoom = mapInstance.getZoom();
          setZoom(mapZoom!);
        }, 500),
        mapId
      );

      Object.keys(eventListeners).forEach(eventName => {
        const handlers = eventListeners[eventName];
        if (typeof handlers === 'function') {
          addListener(eventName, mapInstance, handlers, mapId);
        }
        if (Array.isArray(handlers)) {
          handlers.forEach(handler => {
            addListener(eventName, mapInstance, handler, mapId);
          });
        }
      });
      // Set a minZoom option to prevent the user to zoom out too much
      mapInstance.setOptions({ minZoom: 5 });
      setMap(mapInstance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disableControls, eventListeners, zoom, window.google.maps]
  );

  useEffect(() => {
    const shouldLoad = !!domRef.current;

    if (!shouldLoad) {
      return;
    }

    const mapId = getRandomId();

    prepareMap(mapId);

    return () => clearListenerCache(mapId);
    /*
     * NOTE:
     * - Only run when we know the map container has been loaded in the dom ~ isMapMounted
     * - isMapMounted starts as false and is only set to true when we have a dom ref
     * - And the domRef.current will be set
     */
  }, [isMapMounted, window.google.maps]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLoadedIntoDom = useCallback((ref: HTMLDivElement) => {
    if (!domRef.current && ref) {
      domRef.current = ref;
      setIsMapMounted(true);
    }
  }, []);

  const panTo = useCallback<PanToFn>(
    async newPosition => {
      if (!map) {
        return;
      }

      if (isValidPosition(newPosition)) {
        map.panTo(newPosition);
      }

      if (map.getZoom()! < DEFAULT_MAP_ZOOM && newPosition !== defaultPosition) {
        map.setZoom(DEFAULT_MAP_ZOOM);
      }
    },
    [defaultPosition, map]
  );

  useEffect(() => {
    if (map && isValidPosition(position)) {
      panTo(position);
    }
    // We recreate a new object above each render, which makes a simple object
    // compare fail. We only want to run this effect when the actual values change
  }, [map, position?.lat, position?.lng]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Creates a marker for a given type and location. If marker
   * already placed on map, updates accordingly.
   */
  const createMarker = useCallback(
    (markerInput: IMarkerInput): string => {
      if (!libraryLoaded || !map) {
        return '';
      }

      const { type, location, onPress, isSelected } = markerInput;
      const key = getKeyForMarkerInput(markerInput, checkSingleUseMarker(type));

      if (!isValidPosition(location)) {
        return key; // bail if location is not known
      }

      // This updates the marker if it has already been added to map.
      // Set selected marker key
      selectedMarkerKey.current = isSelected ? key : selectedMarkerKey.current;

      const iconMap = enableMapListExperiment && !isStaticMap ? markersUrisRevamp : markersUris;

      const existingMarker = markers.current[key]?.marker;
      if (existingMarker) {
        existingMarker.setMap(map);

        window.google.maps.event.clearInstanceListeners(existingMarker);
        window.google.maps.event.addListener(existingMarker, 'click', onPress!);

        // Update Icon
        if (markers.current[key].type !== type || markers.current[key].isSelected !== isSelected) {
          markers.current[key].type = type;
          markers.current[key].isSelected = isSelected;
          const updatedIcon = isSelected ? iconMap[MarkerTypes.StoreFocused] : iconMap[type];
          existingMarker.setIcon(updatedIcon);
        }

        // Update position
        existingMarker.setPosition(location);
        return key;
      }

      const icon = isSelected ? iconMap[MarkerTypes.StoreFocused] : iconMap[type];
      const marker = new window.google.maps.Marker({
        position: location,
        map,
        clickable: !!onPress,
        icon,
        title: markerInput.title,
      });
      if (onPress) {
        window.google.maps.event.addListener(marker, 'click', onPress);
      }

      markers.current[key] = { id: key, marker, type, location, isSelected, onPress };
      return key;
    },
    [enableMapListExperiment, libraryLoaded, map]
  );

  const handleOnMarkerListItemSelect = useCallback(
    (markerInput: IMarkerInput) => {
      //Unselect current selected marker
      const selectedMarker = selectedMarkerKey.current
        ? markers.current[selectedMarkerKey.current]
        : undefined;
      selectedMarker &&
        createMarker({
          ...selectedMarker,
          type: selectedMarker.type,
          location: selectedMarker.location,
          onPress: selectedMarker.onPress,
          isSelected: false,
        });
      // Select a new marker
      createMarker({ ...markerInput, isSelected: true });
      panTo(markerInput.location);
    },
    [createMarker, panTo]
  );

  const createMarkerList = useCallback(
    (markerList: IMarkerInput[]) => {
      markerList.forEach(markerInput => {
        createMarker({
          ...markerInput,
          onPress: () => {
            handleOnMarkerListItemSelect(markerInput);
            markerInput.onPress?.();
          },
        });
      });
    },
    [createMarker, handleOnMarkerListItemSelect]
  );

  const fitAndCenterFromCoords = useCallback<FitAndCenterFromCoordsFn>(
    (coordsArray: CenterPoint[]) =>
      debounce(() => {
        if (!libraryLoaded || !map) {
          return;
        }
        if (coordsArray.length >= 2) {
          const markerBounds = coordsArray.reduce((bounds, coord) => {
            bounds.extend(coord);
            return bounds;
          }, new window.google.maps.LatLngBounds());
          const marker1 = new window.google.maps.LatLng({
            lat: markerBounds.getSouthWest().lat(),
            lng: markerBounds.getSouthWest().lng(),
          });
          const marker2 = new window.google.maps.LatLng({
            lat: markerBounds.getNorthEast().lat(),
            lng: markerBounds.getNorthEast().lng(),
          });
          if (!marker1.equals(marker2)) {
            // Adding a 100px padding around the bounds to cover for
            // icons at the edge
            map.fitBounds(markerBounds, 100);
          }
        }
      }, 500),
    [libraryLoaded, map]
  );

  const mapElement = React.createElement(MapContainer, { ref: onLoadedIntoDom });

  const updateMarkerPosition = useCallback((markerKey: string, location: CenterPoint) => {
    markers.current[markerKey]?.marker?.setPosition(location);
  }, []);

  return {
    center,
    createMarker,
    createMarkerList,
    fitAndCenterFromCoords,
    map: mapElement,
    panTo,
    updateMarkerPosition,
    zoom,
  };
};

export default useMap;

import { useCallback, useMemo, useRef } from 'react';

import { IBraze } from 'braze-web-sdk';

import { useApiKey } from 'hooks/configs/use-api-key';
import { useConfigValue } from 'hooks/configs/use-config-value';
import useEffectOnUnmount from 'hooks/use-effect-on-unmount';
import useEffectOnce from 'hooks/use-effect-once';
import useReadyQueue from 'hooks/use-ready-queue';
import Braze, { ContentCard } from 'utils/braze';
import { region } from 'utils/environment';
import { Region } from 'utils/environment/types';
import logger from 'utils/logger';
import noop from 'utils/noop';

import { IBrazeContext } from '../types';

const useBraze = () => {
  const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
  const brazeKey = useApiKey({ key: 'braze' });
  const brazeUrl = useConfigValue({ key: 'urls', defaultValue: {} }).braze;

  const toggleContentCards = useCallback<IBrazeContext['toggleContentCards']>(
    enqueueIfNotDrained((parentNode: Element) => {
      // @ts-ignore TS is reading types from mobile file. Signatures are different compared with Braze.web
      Braze.launchContentCards(parentNode);
    }),
    [enqueueIfNotDrained]
  );

  const setUserId = useCallback<IBrazeContext['setUserId']>(
    enqueueIfNotDrained((userId: string | null) => {
      // @ts-ignore TS is reading types from mobile file. Signatures are different compared with Braze.web
      Braze.changeUser(userId);
      return Braze.requestContentCardsRefresh();
    }),
    []
  );

  // Making this async to be compatible with the react native module
  const getCachedContentCards = useCallback<IBrazeContext['getCachedContentCards']>(async () => {
    // @ts-ignore TS is reading types from mobile file. Signatures are different compared with Braze.web
    const { cards = [] } = Braze.getContentCards() || {};
    return cards as ContentCard[];
  }, []);

  const initBrazeRef = useRef(() => {});
  const initWasCalled = useRef(false);

  initBrazeRef.current = () => {
    import('../init')
      .then(({ default: init }) => {
        if (initWasCalled.current) {
          return;
        }
        init(brazeKey, brazeUrl, drainQueue, {
          // currently not enabled for CA
          enableHtmlInAppMessagesInBrazeSdkConfig: region === Region.US,
        });
        initWasCalled.current = true;
      })
      .catch(error => {
        logger.error({ error, message: 'Braze: import error' });
      });
  };

  // TODO: check what should happen when the deps changes. Should this hook act reactively?
  useEffectOnce(() => {
    initBrazeRef.current?.();
  });

  useEffectOnUnmount(() => {
    // braze requests are batched and flushed.
    // if the app is unmounting we should immediately
    // send anything in the queue
    Braze.requestImmediateDataFlush();
  });

  const ctxValue: IBrazeContext = useMemo(
    () => ({
      initBraze: initBrazeRef.current,
      getCachedContentCards,
      setUserId,
      toggleContentCards,
      isInAppMessageOpen: false,
      setIsInAppMessageOpen: noop,
    }),
    [getCachedContentCards, setUserId, toggleContentCards]
  );

  return ctxValue;
};

export default useBraze;

declare global {
  interface Window {
    braze: IBraze;
    rbiBraze?: IBraze;
    rbiBrazeInit?: () => void;
  }
}

import { CardTypes, IPaymentMethod } from 'state/payment/types';

export const isCreditCardPaymentMethod = (method: IPaymentMethod) =>
  method.credit &&
  method.credit?.cardType !== CardTypes.APPLE_PAY &&
  method.credit?.cardType !== CardTypes.GOOGLE_PAY;

export const filterUnselectedMethods = (unselectedMethods: IPaymentMethod[]): IPaymentMethod[] => {
  const filteredMethods: IPaymentMethod[] = [];
  const cardTypesAdded = new Set<string>();

  unselectedMethods.forEach((item: IPaymentMethod) => {
    // Check if the item should be added
    if (!item.cash && !item.credit && !item.paypal && !item.prepaid && !item.venmo) {
      filteredMethods.push(item);
      return;
    }

    if (item.credit) {
      const cardType = item.credit.cardType;
      if (
        (cardType === CardTypes.VISA && !cardTypesAdded.has(CardTypes.VISA)) ||
        (cardType === CardTypes.GOOGLE_PAY && !cardTypesAdded.has(CardTypes.GOOGLE_PAY)) ||
        (cardType === CardTypes.APPLE_PAY && !cardTypesAdded.has(CardTypes.APPLE_PAY))
      ) {
        filteredMethods.push(item);
        cardTypesAdded.add(cardType);
      }
    } else {
      filteredMethods.push(item);
    }
  });

  return filteredMethods;
};

import { Platform } from 'react-native';

import { Stage } from 'generated/graphql-gateway';
import { appVersion } from 'utils/app-version-info';
import { LogLevel } from 'utils/logger';

import { apiKeyLogger } from './api-key-logger';
import { getConfigValue } from './config';
import {
  GraphQLEnv,
  RBIAppUriScheme,
  RBIBrand,
  RBIEnv,
  RBIExpandedPlatform,
  RBIFullBrandName,
  RBIPlatform,
  Region,
} from './types';

export * from './config';

const brandToFullBrandName = {
  [RBIBrand.PLK]: RBIFullBrandName.Popeyes,
  [RBIBrand.TH]: RBIFullBrandName.TimHortons,
  [RBIBrand.BK]: RBIFullBrandName.BurgerKing,
  [RBIBrand.FHS]: RBIFullBrandName.FirehouseSubs,
};

const brandToUriScheme = {
  [RBIBrand.PLK]: RBIAppUriScheme.Popeyes,
  [RBIBrand.TH]: RBIAppUriScheme.TimHortons,
  [RBIBrand.BK]: RBIAppUriScheme.BurgerKing,
  [RBIBrand.FHS]: RBIAppUriScheme.FirehouseSubs,
};

export const minDevLogLevel = () => getConfigValue({ key: 'minDevLogLevel' }) as LogLevel;
export const brand = () => getConfigValue({ key: 'brand' }) as RBIBrand;
export const env = () => (getConfigValue({ key: 'env' }) || RBIEnv.DEV) as RBIEnv;
export const platform = () => getConfigValue({ key: 'platform' }) as RBIPlatform;
export const checkPlatform = (is: RBIPlatform) => is === platform();
export const checkRbiEnv = (is: RBIEnv) => is === env();
export const graphqlEnv = () => (getConfigValue({ key: 'graphqlEnv' }) || env()) as GraphQLEnv;
export const graphqlGatewayEnv = () =>
  (getConfigValue({ key: 'graphqlGatewayEnv' }) || env()) as GraphQLEnv;
// TODO: RN - locale switching -- should assess if we even need this - ever a case where sanityEnv !== env?
export const sanityEnv = () => (getConfigValue({ key: 'sanityEnv' }) || env()) as RBIEnv;
export const welcomeEmailDomain = () =>
  (process.env.REACT_APP_RBI_WELCOME_EMAIL_DOMAIN || env()) as Stage;
export const fullBrandName = () => brandToFullBrandName[brand()];
export const appUriScheme = () => brandToUriScheme[brand()];
export const region = getConfigValue({ key: 'buildRegion' }) || 'US';

type RBIReleaseTimestamp = string;
export const releaseTimestamp = () =>
  getConfigValue({ key: 'releaseTimestamp' }) as RBIReleaseTimestamp;

// TODO: RN - locale switching -- should pull from specific region
// Our Dataset is always of the form ENV_BRAND except in test ENV where we always use 'automation'
export const sanityDataset = () =>
  getConfigValue({ key: 'sanityDataset' }) || `${sanityEnv()}_${brand()}`;

export { RBIBrand, RBIEnv, RBIPlatform, RBIExpandedPlatform, GraphQLEnv };

export const isLocalDev = process.env.NODE_ENV !== 'production';
export const isTest = process.env.NODE_ENV === 'test';
export const isProduction = checkRbiEnv(RBIEnv.PROD);
export const isWeb = Platform.OS === 'web';
export const isNative = !isWeb;
export const isMobile = () => /Mobile/.test(navigator.userAgent) || isNative;
export const isIOS = () => {
  if (Platform.OS === 'ios') {
    return true;
  }
  if (Platform.OS === 'android') {
    return false;
  }

  return (
    new Set(['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']).has(
      navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
export const isNativeIOS = () => isNative && isIOS();

export const isNativeAndroid = () => isNative && !isIOS();

export const getCurrentCommitHash = () => getConfigValue({ key: 'revisionId' }) || env();

export const getBuildDate = () => getConfigValue({ key: 'buildDate' });

export const versionIsGreaterThanOrEqualTo = ({
  versionString,
  majorVersionGreaterThanOrEqualTo,
}: {
  versionString: string | null;
  majorVersionGreaterThanOrEqualTo: number;
}): boolean => {
  if (!versionString) {
    return false;
  }
  const majorVersion = Number(versionString.split('.')[0]);
  return majorVersion >= majorVersionGreaterThanOrEqualTo;
};

export const minimumSignupAge = () => getConfigValue({ key: 'minimumSignupAge' });

export const enableNumericPhoneValidation = () =>
  getConfigValue({ key: 'enableNumericPhoneValidation' });

/** Simple function to see if we're running the app locally. */
export const isRunningLocally = () =>
  window.location.hostname === 'localhost' || !isNaN(Number(window.location?.hostname?.charAt(0)));

interface IGetApiKeyProps<T> {
  key: string;
  defaultValue?: T;
  region?: Region;
}
// Initiate an empty dictionary to prevent duplicates DD logs of missing api key
let missingApiKeys = {};

/**
 * @note
 * !!DO NOT USE THIS FUNCTION DIRECTLY!!
 * should use `useApiKey` hook instead where possible
 * this function should only be used directly by `useApiKey`
 *  and in cases where LocaleProvider is not yet available
 *  (ex. LDProvider)
 */
export const getApiKey = <T extends string>({
  key,
  defaultValue,
  region,
}: IGetApiKeyProps<T>): string | T => {
  const keys = getConfigValue({ key: 'apiKeys', region });
  const currentEnv = env();
  // Make sure we haven't already log the missing API keys
  if ((!keys || !keys[key]) && !missingApiKeys[String(key)]) {
    missingApiKeys[String(key)] = true;
    const error = `Missing API key in the Sanity FE configs. API Key: ${key}, Fallback: ${
      defaultValue === '' ? 'None' : `"${defaultValue}"`
    }.`;
    const ctx = {
      appVersionCode: appVersion,
      brand: brand(),
      platform: platform(),
      stage: currentEnv,
      reason: 'front end configuration missing an API key',
    };

    apiKeyLogger(error, ctx);

    return defaultValue ?? '';
  }
  return (keys && keys[key]) ?? defaultValue;
};

// This is only for testing purpose.
export const resetMissingApiKeysForTestOnly = () => (missingApiKeys = {});

export const googleServiceWebClientIds = getConfigValue({ key: 'googleServiceWebClientIds' })[
  env()
];

export const appleWebClientIds = getConfigValue({ key: 'appleWebClientIds' })[env()];

export const facebookClientIds = getConfigValue({ key: 'facebookClientIds' });

import React, { FC } from 'react';

import { Box, Icon, IconButton } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { BackArrow } from 'components/navigation/back-arrow';
import { CancelSearchIcon } from 'pages/store-locator/components/header/cancel-search-icon';
import { useEnableMapListExperiment } from 'pages/store-locator/use-enable-map-list-experiment';
import { theme } from 'styles/configure-theme';

import {
  Header,
  HeaderBackground,
  HeaderWrapper,
  ToggleViewButtonWrapper,
} from '../../store-locator.styled';
import { ClearStoreSmButton } from '../clear-store-sm-button';
import { ToggleViewButton } from '../toggle-view-button';

import { ILocatorHeaderProps } from './types';

export const LocatorHeaderBase: FC<ILocatorHeaderProps> = ({
  currentView,
  onViewChange,
  onSearchPress,
  searchFieldActive,
}) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'selectLocation' });
  const mapListExperimentEnabled = useEnableMapListExperiment();

  return (
    <HeaderBackground>
      <HeaderWrapper>
        <Box flexDirection={'row'}>
          {mapListExperimentEnabled && (
            <Box justifyContent={'center'}>
              <BackArrow color={theme.token('text-button-reversed')} />
            </Box>
          )}
          <ClearStoreSmButton />
        </Box>
        <Box accessible focusable accessibilityLabel={label} accessibilityRole="header">
          <Header variant="headerThree" margin={0}>
            {label}
          </Header>
        </Box>

        <ToggleViewButtonWrapper>
          {!mapListExperimentEnabled && (
            <ToggleViewButton currentView={currentView} updateStoreLocatorView={onViewChange} />
          )}
          {mapListExperimentEnabled && (
            <IconButton
              variant="ghost-reversed"
              onPress={onSearchPress}
              testID="search-store-sm-button"
              icon={
                searchFieldActive ? (
                  <CancelSearchIcon aria-hidden />
                ) : (
                  <Icon variant="search" color={theme.token('text-button-reversed')} aria-hidden />
                )
              }
            />
          )}
        </ToggleViewButtonWrapper>
      </HeaderWrapper>
    </HeaderBackground>
  );
};

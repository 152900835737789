/*
 * This enum maintains a list of currently running a/b experiments.
 * There are strong expectations with this last so please do read this and follow the expectations.
 *
 * 1. These experiments have a defined lifetime coordinated within amplitude.
 *      When an experiment ends, the enum should be removed, and all the coordinated code should be removed
 *      only keeping around the winner of the a/b experiment.
 * 2. The naming of these keys should exactly match the name of the test within amplitude.
 *      This will keep consistency, and improve searchability while tracking down experiments from amplitude to the code.
 * 3. Each key should be surround by a comment block, explaining the experiment and any information related to the code
 *      that a developer may benefit from when trying to trace logic through the codebase. Remember experiments can introduce
 *      bugs to code outside of the experiment, and its important that we keep the business logic changes as thoroughly explained
 *      as possible.
 */

// 🚨🚨🚨 IMPORTANT STABILITY NOTE WITH EXPERIMENTS 🚨🚨🚨
// Experiments must be "safe" by default.
// Never add or use an experiment in a way that causes the app to fail to function properly if:
// - Amplitude network call fails etc.
// - Amplitude loads slowly (remember the app load does NOT block on amplitude)
// - Amplitude value changes during use of the app (remember the app load does NOT block on amplitude and it can be slow to load)
// - Our Amplitude wrapper caches an empty object in local storage from a previous failure
// See here for an example that caused the cart to not display https://github.com/rbilabs/bk-us-app/pull/266/files

export enum RunningExperiments {
  // Experiment to hide tsm, the points notification and the loyalty banner from home page.
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/92058/plan
  // FIXME: Remove after completing the experiment
  HideTsmPointsNotificationLoyaltyBanner = 'hide-tsm-pointsnotification-loyaltybanner',

  // Ongoing Menu A/B Experiment
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/110962/configure
  OngoingMenuExperiment = 'menu-a-b',

  // validate a cc number is valid in the client
  ValidateCCNumberInClient = 'validate-cc-number-in-client',

  // Menu Reorder Widget
  // https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/232310/configure
  // FIXME: Remove after completing the experiment
  MenuReorderWidget = 'menu-reorder-widget',

  // Top Service Mode (TSM) on the menu sections screen
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/289458/settings
  MenuTopServiceMode = 'menu-top-service-mode-header',

  // Incentive Detail button copy experiment
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/231768/settings
  // FIXME: Remove after completing the experiment
  OfferAndRewardDetailButtonCopy = 'offer-and-reward-detail-button-copy',

  // Offer groups
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/233491/settings
  // FIXME: Remove after completing the experiment
  OfferGroups = 'offer-groups-experiment',

  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/233491/settings
  // FIXME: Remove after completing the experiment
  UserPositionAccuracy = 'user-position-accuracy',

  // Map List
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/250872/settings
  // FIXME: Remove after completing the experiment
  MapList = 'enable-map-list-experiment',

  // My Code Redesign
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/247341/settings
  // FIXME: Remove after completing the experiment
  MyCodeRedesign = 'my-code-redesign-experiment',

  // Payment checkout redesign
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/285249/settings
  // FIXME: Remove after completing the experiment
  PaymentCheckoutRedesign = 'enable-payment-checkout-redesign',

  // Cart V2
  // @see https://app.amplitude.com/experiment/restaurantbrandsintl/253543/config/300874/settings
  // FIXME: Remove after completing the experiment
  CartV2 = 'enable-cart-v2-experiment',
}
